.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #fff;
}

.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
  margin-top: 0px;
}

.ant-layout-sider-zero-width-trigger {
  background: #f58922 !important;
}
.ant-form-item-label {
  padding: 0px !important;
}

.logo {
  /* height: 15% !important; */
  width: 100% !important;
  object-fit: contain;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  transition-delay: 2s;
  transition-property: width;
  transition-duration: 5s;
}

.logo:hover {
  -webkit-filter: drop-shadow(5px 5px 25px #f58922);
  filter: drop-shadow(5px 5px 25px #f58922);
}

.site-layout-sub-header-background {
  background: #fff !important;
}

.site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout-sub-header-background {
  background: #141414;
}

[class~="ant-col"] {
  background: transparent;
  border: 0;
}

[class~="ant-col"] > div {
  height: 120px;
  font-size: 14px;
  line-height: 120px;
  background: #f58922;
  border-radius: 4px;
}

pre {
  padding: 8px 16px;
  font-size: 13px;
  background: #f9f9f9;
  border-radius: 6px;
}

pre.demo-code {
  direction: ltr;
}

[data-theme="dark"] [class~="ant-col"] > div {
  background: #028ac8;
}

.Activecourse {
  background-color: #fff;
  padding: 20px;
  width: 350px;
  text-align: center;
}

.Activecourse:hover {
  background-color: #fff;
  box-shadow: 2px 2px 90px 1px grey;
}

@media (max-width: 400px) {
  .activecoursebox {
    background-color: #fff;
    width: 100%;
  }

  .Activecourse {
    background-color: #fff;
    padding: 20px;
    width: 100%;
    text-align: center;
  }

  .Activecourse:hover {
    background-color: #fff;
    box-shadow: 2px 2px 90px 1px grey;
  }
}

.ant-card-grid.Activecourse.ant-card-grid-hoverable {
  width: 100%;
  text-align: center;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

[class~="ant-col"] > div {
  height: auto;
  font-size: 14px;
  line-height: 42px;
  background: white;
  border-radius: 4px;
}

.site-card-wrapper {
  background-color: transparent;
}

.table {
  line-height: 60px;
  text-align: center;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  margin-top: 135px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.tabelrow {
  line-height: 60px;
  text-align: center;
  background: white;
  border-bottom: 1px solid #f0f0f0;
  margin-top: 135px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.tabelrow:hover {
  line-height: 60px;
  text-align: center;
  background: #ebfaf3;
  border-bottom: 1px solid #f0f0f0;
  margin-top: 135px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  transition: 0.7s;
}

.shButton {
  background-color: #f58922 !important;
  color: #fff !important;
  text-decoration: none !important;
  border: none;
}

.shButton:hover {
  background-color: #ebfaf3 !important;
  color: #000000 !important;
  text-decoration: none !important;
  border: none;
}

.ant-menu-item {
  background-color: #34b2c417;
  color: black !important;
  text-decoration: none !important;
  border-radius: 30px 0px 30px 30px !important;
}

.ant-menu-item:hover {
  background-color: #34b2c417;
  color: black !important;
  text-decoration: none !important;
  border-radius: 30px 0px 30px 30px !important;
}

.ant-menu-item-selected {
  background-color: #f58922 !important;
  color: #fff !important;
  text-decoration: none !important;
  border-radius: 30px 0px 30px 30px !important;
}

.ant-menu-item-selected:hover {
  text-decoration: none !important;
  color: #fff !important;
  border-radius: 30px 0px 30px 30px !important;
}

.ant-menu-item a {
  text-decoration: none !important;
}

.ant-menu-item a:hover {
  text-decoration: none !important;
}

.ant-menu-submenu-selected {
  color: #000000 !important;
  text-decoration: none;
}

.ant-menu-submenu-title {
  color: #000000 !important;
  text-decoration: none;
}

.ant-layout-content {
  flex: auto;
  min-height: 550px !important;
  margin: 30px !important;
}

.ant-card {
  border: #f58922;
  border-color: #f58922 !important;
}
.ant-card-head {
  border-color: #f58922 !important;
}

.profile-square {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 10px;
  float: right !important;
}

.ant-layout-sider {
  background: #ffffff !important;
}
.login-layout {
  min-height: 90vh;
  padding-top: 30%;
  padding-bottom: 30%;
  text-align: "center";
}

@media screen and (min-width: 1024px) and (max-height: 1310px) {
  .login-layout {
    min-height: 100vh;
    padding-top: 8%;
    padding-bottom: 8%;
    text-align: "center";
  }
}
